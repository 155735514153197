import { PoNVoid, Template } from '@/types'
import { calculateAspectRatio, cls, preventDefault, stopPropagation, whisper } from '@/utils'
// import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import { usePathname, useRouter } from 'next/navigation'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import Video from '../video'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
import IconArrowRight from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import Button from '../button'
import { useSetAtom } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom } from '@/atoms'

export interface TemplateCardProps {
  className?: string
  data?: Template
  onCreate?: (template: Template) => PoNVoid
  style?: React.CSSProperties
}

const pathnamesWithCreationInput = [/^\/$/, /^\/creations$/, /^\/explore$/, /^\/templates$/, /^\/template\/.+/]

export default function TemplateCard({ className, data, onCreate, style }: TemplateCardProps) {
  const router = useRouter()
  const canGotoDetail = data?.template_id && !data?.template_id.startsWith('demo:')
  const setCreationInput = useSetAtom(creationInputAtom)
  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)
  const pathname = usePathname()
  const coverAspectStyle: CSSProperties = useMemo(() => {
    const { width, height } = data?.cover_image_spec ?? {}
    const aspectRatio = calculateAspectRatio(width, height)
    return {
      aspectRatio,
    }
  }, [data?.cover_image_spec])

  const handleGotoDetail = useCallback(() => {
    if (canGotoDetail) {
      router.push(`/template/${data?.template_id}`)
    }
  }, [data, router, canGotoDetail])

  const handleCreate = useCallback(
    (e: any) => {
      preventDefault(e)
      stopPropagation(e)

      if (!data?.template_id) {
        return
      }

      setCreationInput((prev) => {
        return {
          ...prev,
          mode: undefined,
          expanded: true,
        }
      })

      setActiveTemplateId(data?.template_id)

      const needRedirect = !pathnamesWithCreationInput.some((p) => p.test(pathname))

      if (needRedirect) {
        router.push('/creations')
      }

      onCreate?.(data)
    },
    [data, router, pathname, setCreationInput, setActiveTemplateId, onCreate],
  )

  const [coverLoadFailed, setCoverLoadFailed] = useState(false)

  const coverVideo = data?.examples?.[0]?.media_url

  return (
    <div
      className={cls(
        'group rounded-md md:rounded-xl md:p-2 bg-surface flex flex-col gap-2 cursor-pointer md:border-2 md:border-b-4 md:border-border md:hover:border-border-hover overflow-hidden h-max max-h-max',
        canGotoDetail ? 'cursor-pointer' : 'cursor-default',
        className,
      )}
      style={style}
      onClick={handleGotoDetail}
    >
      <div className='relative w-full rounded-md overflow-hidden' style={coverAspectStyle}>
        {coverLoadFailed || (!data?.cover_image && !coverVideo) ? (
          <div
            className='w-full rounded-md overflow-hidden bg-surface-subdued flex items-center justify-center text-body-lg font-medium tracking-32'
            style={coverAspectStyle}
          >
            {data?.name}
          </div>
        ) : coverVideo ? (
          <Video
            loop
            // controls
            playOnHover
            resetOnBlur
            muted
            playsInline
            src={coverVideo}
            poster={data?.cover_image}
            className='w-full h-full object-cover object-top'
            onError={() => setCoverLoadFailed(true)}
            onClick={handleGotoDetail}
          />
        ) : (
          <img
            src={data?.cover_image}
            alt={data?.name}
            className='w-full h-full object-scale-down'
            onError={() => setCoverLoadFailed(true)}
          />
        )}
      </div>
      <div className='flex flex-col items-center w-full justify-between text-text' aria-label='template card footer'>
        <div className='flex items-center w-full justify-between h-8 md:px-1 text-text gap-2'>
          <span className='text-body-sm font-medium tracking-15 line-clamp-2 leading-4 md:leading-5'>{data?.name}</span>
          <div className='flex items-center gap-2'>
            <Button className='size-8 p-0' tooltip='Create' onClick={handleCreate}>
              <IconPlus className='size-5 text-icon' />
            </Button>
            <Button className='size-8 p-0' tooltip='View detail'>
              <IconArrowRight className='size-5 text-icon' />
            </Button>
          </div>
        </div>
        {data?.tagline && (
          <span className='text-body-md font-medium tracking-15 line-clamp-2 text-text-subdued w-full px-1'>
            {data?.tagline}
          </span>
        )}
      </div>
    </div>
  )
}
